import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { Spinner } from '@/components/base-components/Spinner';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary-600 text-white fill-white stroke-white hover:bg-primary-700 active:bg-primary-600 active:shadow-[0px_0px_0px_4px_#F4EBFF] disabled:bg-primary-200 disabled:active:bg-primary-200 disabled:active:shadow-transparent',
        outline:
          'bg-white text-gray-700 fill-gray-700 border border-gray-700 hover:bg-gray-50 hover:border-gray-300 active:bg-white active:border-gray-300 active:shadow-[0px_0px_0px_4px_#F2F4F7] disabled:bg-white disabled:border-gray-200 disabled:text-gray-300 disabled:active:bg-white disabled:active:shadow-transparent',
        tertiary:
          'text-gray-600 fill-gray-600 hover:text-gray-600 hover:fill-gray-600 active:bg-gray-50 disabled:text-gray-300 disabled:fill-gray-300 disabled:active:bg-transparent disabled:active:shadow-transparent',
        secondaryTertiary:
          'bg-primary-50 text-primary-700 fill-primary-700 hover:bg-primary-100 active:bg-primary-50 active:shadow-[0px_0px_0px_4px_#F4EBFF] disabled:bg-primary-25 disabled:text-primary-300 disabled:active:bg-primary-25 disabled:active:shadow-transparent',
        success: 'bg-success-600 text-white hover:bg-success-700',
        error: 'bg-error-600 text-white hover:bg-error-700',
        warning: 'bg-warning-400 text-white hover:bg-warning-400',
        link: 'text-primary-600 underline-offset-4 hover:underline',
        ghost: 'text-gray-500 underline-offset-4',
        secondaryGray:
          'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50',
        secondaryError:
          'border border-error-300 bg-white text-error-700 hover:bg-error-50',
        secondaryPrimary:
          'border border-primary-500 bg-white text-primary-700 hover:bg-primary-50',
        tertiaryGray: 'bg-white text-gray-700 hover:bg-gray-50',
      },
      size: {
        lg: 'h-11 px-4 py-2 typo-t-md-bold',
        sm: 'h-9 px-3 typo-t-sm-semibold',
        md: 'h-10 px-4 typo-t-sm-semibold',
        xl: 'h-12 px-5 typo-t-md-bold',
        '2xl': 'h-14 px-7',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'lg',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      startIcon,
      endIcon,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          'flex flex-row items-center gap-2',
          buttonVariants({ variant, size, className }),
        )}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {startIcon && <span className="w-5">{startIcon}</span>}
            <span className="button-content">{props.children}</span>
            {endIcon && <span className="end-icon w-5">{endIcon}</span>}
          </>
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
