'use client';
import * as Sentry from '@sentry/nextjs';
import { Button } from '@/components/ui/button';

/**
 * @inheritDoc
 */
const GlobalError = ({ error, reset }: { error: Error; reset: () => void }) => {
  Sentry.captureException(error); // Report error to Sentry

  return (
    <html>
      <body>
        <h1>Something went wrong!</h1>
        <p>{error.message}</p>
        <Button onClick={reset}>Try again</Button>
      </body>
    </html>
  );
};

export default GlobalError;
